<template>
  <Container>
    <v-row class="mb-10">
      <v-col cols="12">
        <slot />
      </v-col>

      <PostFeedCard
        v-for="(article, i) in postsGetter.posts"
        :key="article.id"
        :size="layout[i]"
        :value="article.post_id ? article.post_id : article"
        :tag_id="article.tag_id.id"
        :type="type"
      />
    </v-row>

    <div class="text-center" v-if="postsGetter.page_count > 1">
      <v-pagination
        style="fontFamily: monospace;"
        v-model="postsGetter.page"
        :length="postsGetter.page_count"
        @input="input"
      ></v-pagination>
    </div>
    <!-- <v-btn @click="test">click me</v-btn> -->
    <!-- <div>{{ page }}</div> -->

    <!-- <v-row align="center" dir="ltr">
      <v-col cols="3">
        <base-btn
          v-if="page !== 1"
          class="ml-0"
          square
          title="Previous page"
          @click="page--"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </base-btn>
      </v-col>

      <v-col class="text-center subheading" cols="6">
        PAGE {{ page }} OF {{ pages }}
      </v-col>

      <v-col class="text-right" cols="3">
        <base-btn
          v-if="pages > 1 && page < pages"
          class="mr-0"
          square
          title="Next page"
          @click="page++"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </base-btn>
      </v-col>
    </v-row> -->
  </Container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// Utilities
// import { mapState } from 'vuex';

export default {
  name: 'PostsFeed',
  props: ['articles', 'type'],
  components: {
    PostFeedCard: () => import('@/components/PostFeedCard'),
  },

  data: () => ({
    layout: [2, 2, 1, 2, 2, 3, 3, 3, 3, 3, 3],
    // layout: [2, 2, 1, 2, 2, 3, 3, 3, 3, 3, 3],
    page: 1,
    // pageSize: 2,
    // x: 1,
  }),

  methods: {
    ...mapActions(['getPostsAction']),
    input(newPage) {
      this.page = newPage;

      this.getPostsAction({ page: this.page, type: this.$route.query.type });
      console.log('newPage', this.page);

      window.scrollTo(0, 0);
    },
  },

  computed: {
    ...mapGetters(['postsGetter']),
    // pages() {
    //   return Math.ceil(this.postsGetter.posts.length / this.postsGetter.limit);
    // },
    // paginatedArticles() {
    //   const start = (this.postsGetter.page - 1) * this.postsGetter.limit;
    //   const stop = this.postsGetter.page * this.postsGetter.limit;

    //   return this.postsGetter.posts.slice(start, stop);
    // },
    // currentPage: {
    //   // this.page = this.postsGetter.page;
    //   // return this.postsGetter.page;
    //   get: function() {
    //     return this.x;
    //   },
    //   set: function(newValue) {
    //     console.log('newval', newValue);
    //     this.page = 2;
    //   },
    // },
  },

  watch: {
    // page() {
    //   // fetch data
    //   console.log('call api', this.page);
    //   console.log(this.page);
    //   this.getPostsAction(this.page);
    //   window.scrollTo(0, 0);
    //   // this.currentPage()
    // },
    // currentPage() {
    //   console.log('new val');
    //   this.page = 2;
    // },
  },
  mounted() {
    console.log('len', this.postsGetter.posts);
    // this.page = this.postsGetter.page;
  },
};
</script>
